import React from 'react';
import '../App.css';

const Footer = props => {

    const footStyle = {
        display: 'flex', 
        flexDirection: 'row',
        flexWrap: 'nowrap',
        color: `#${props.highlightColor}`,
        width: '100%',
        backgroundColor: `#${props.lowlightColor}`,
    }

    return(
        <div className="footer" style= { footStyle }>
            <span className="footerflex"></span>
            <p className="footname">© {new Date().getFullYear()} Bullet Points Monthly</p>
        </div>
    )
}

export default Footer;
