import React from 'react';
import ArticleContainer from "./ArticleContainer.js"
import SideBar from './SideBar.js';

const Main = props => {
  let articlePathRegex = /\/\d\d\d\d\/\d\d\/\d\d\/[a-z\d](?:[a-z\d_-]*[a-z\d])?/
  return (
    <div className="main" style = {{backgroundColor: `#${props.lowlightColor}`}}>
      <SideBar
        topic = {props.topic}
        slug = {props.slug}
        highlightColor = {props.highlightColor}
        lowlightColor = {props.lowlightColor}
      />
      <ArticleContainer
        articles = {props.articles}
        highlightColor = {props.highlightColor}
        lowlightColor = {props.lowlightColor}
      />
    </div>
  );
}

export default Main;
