import React from 'react';
import { Link } from 'gatsby';

const ArticleContainer = props => {

  let labelStyles={
    backgroundColor : `#${props.lowlightColor}`,
    color: `#${props.highlightColor}`
  }

  const generateArticlePreviews = () =>{
    let sortedArticles = props.articles.sort((first, second) => {
      let firstDate = new Date(first.pubDate)
      let secondDate = new Date(second.pubDate)
      return firstDate - secondDate
    })
    return sortedArticles.map((article, index) => {
      let articleStyles={
        borderColor: `#${props.highlightColor}`,
        backgroundColor: `#${props.highlightColor}`,
        backgroundImage: `url("${article.heroImageUrl}")`,
        backgroundPosition: 'center'
      }

      let slugDate = article.pubDate.split('-').join('/')
      let slugText = article.slug
      let articleSlug = `/${slugDate}/${slugText}`

        if(!article.title || article.title.length === 0){
          return (
            <div key={index} className="articlePreload"></div>
          )
        } else if (!!article.title && !!!article.slug){
          return (
          <div key={index} className="articleComing" id={`ar${index+1}`} style={articleStyles}>
            <div className="articleInfo" style= {labelStyles}>
              <span className="title">{article.title}</span><br/>
              <span className="byline">by {article.authorName}</span>
            </div>
          </div>
        )
        }else {
        return (
        <Link key={index} to={articleSlug}>
        <div  className="articlePreview" id={`ar${index+1}`} style={articleStyles}>
          <div className="articleInfo" style= {labelStyles}>
            <span className="title">{article.title}</span><br/>
            <span className="byline">by {article.authorName}</span>
          </div>
        </div>
        </Link>
        )
      }
    })
  }

  return (
    <div className="articleContainer">
    {!!props.articles && generateArticlePreviews()}
    </div>
  );
}

export default ArticleContainer;
