import React, { useState, useEffect } from 'react';
import './App.css';
import './index.css';
import Main from './Containers/Main.js'
import Footer from './Components/Footer.js'
import SideBar from './Containers/SideBar';
import { withRouter } from "react-router-dom";
import { useStaticQuery, graphql } from 'gatsby';
import Supporters from './Components/Supporters';

const  SupportersPage = ({ data, pageContext }) => {
  if (typeof(document) !== 'undefined'){
    document.documentElement.style.background = `#000`
    document.documentElement.style.color = `#FFF`
  }

  return (
    <>
      <div className="main">
        <SideBar
          topic = {'Supporters'}
          lowlightColor={'000'}
          highlightColor={'FFF'}
        />
        <Supporters/>
      </div>
      <Footer/>
    </>
  )
}

export default SupportersPage;

export function Head (){
  return (
    <>
       <meta charSet="utf-8" />
       {/* <link rel="icon" href="%PUBLIC_URL%/favicon.ico" /> */}
       <meta name="viewport" content="width=device-width, initial-scale=1" />
       <meta name="theme-color" content="#000000" />
       <meta name="description"
         content="Monthly magazine for long-form articles and podcasts on videogames"
       />
        <link rel="preconnect" href="https://fonts.gstatic.com" /> 
       <link href="https://fonts.googleapis.com/css2?family=Bitter&family=JetBrains+Mono:wght@400&display=swap" rel="stylesheet" />
       
       <title>Supporters</title>
    </>
  )
}