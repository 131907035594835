import React from 'react';

const Supporters = props => {
  return (
    <div className="aboutDetails">
    <h1>Supporters</h1>
    <p>If you’d like to help us continue to hire freelancers and keep this machine oiled, please consider <a href="https://www.patreon.com/bulletpoints">pledging to our Patreon</a> or spreading the word about the work we’re doing.</p>
    <p>Bullet Points Monthly is supported by these kind people:</p>
      <div className="allSupporters">
        <p>Brent Ables</p>
        <p>Aldowyn</p>
        <p>Andrew Bailey</p>
        <p>Brad Barrett</p>
        <p>Luke Beeman</p>
        <p>John Bowen</p>
        <p>Chris Breault</p>
        <p>Richard Budd</p>
        <p>Forrest Butler</p>
        <p>Joshua Calixto</p>
        <p>CD32</p>
        <p>Khee Hoon Chan</p>
        <p>Jerry Chu</p>
        <p>Yussef Cole</p>
        <p>Davis Cox</p>
        <p>Gavin Craig</p>
        <p>Jason Dafnis</p>
        <p>Alex Dalbey</p>
        <p>Jules de Bellefeuille Defoy</p>
        <p>Billy Easley</p>
        <p>Toussaint Egan</p>
        <p>Edwin Evans-Thirlwell</p>
        <p>Ryan Fairchild</p>
        <p>Dan Fries</p>
        <p>Ethan Gach</p>
        <p>Caitlin Galiz-Rowe</p>
        <p>Lewis Gordon</p>
        <p>Chris Hanson</p>
        <p>Malindy Hetfeld</p>
        <p>Daniel Holmberg</p>
        <p>Oswald Hurlem</p>
        <p>Joe</p>
        <p>Josh</p>
        <p>Agent JR</p>
        <p>Justin Keever</p>
        <p>Owen Ketillson</p>
        <p>Kevin</p>
        <p>Emma Kidwell</p>
        <p>Matthew L</p>
        <p>Thomas Langton</p>
        <p>Leen</p>
        <p>Andrew Lewis</p>
        <p>Yufan Lou</p>
        <p>Eleum Loughney</p>
        <p>Charles MacMullen</p>
        <p>Jacquelyn Marina</p>
        <p>Elias Markström</p>
        <p>Caty McCarthy</p>
        <p>Matt McCoolski</p>
        <p>Miles McCormack</p>
        <p>Corey Milne</p>
        <p>AJ Moser</p>
        <p>Julie Muncy</p>
        <p>Neil</p>
        <p>Trish Osuch</p>
        <p>Ansh Patel</p>
        <p>Ray Porreca</p>
        <p>Sam Pulham</p>
        <p>Devin Raposo</p>
        <p>Nic Reuben</p>
        <p>Callum Robertson</p>
        <p>Levi Rubeck</p>
        <p>Artur Rzepka</p>
        <p>Steven Francisco Santana</p>
        <p>Pedro Santos</p>
        <p>Sean</p>
        <p>Max Soar</p>
        <p>Dan Solberg</p>
        <p>Stefan</p>
        <p>Damian Stewart</p>
        <p>MD Taylor</p>
        <p>Michael Thomsen</p>
        <p>Joannes Truyens</p>
        <p>Rick Vance</p>
        <p>Kennan Ward</p>
        <p>Caleb Wimble</p>
        <p>Sam Zucchi</p>
      </div>
    </div>
  )
}

export default Supporters
